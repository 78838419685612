import React from 'react'

import './styles.scss'

import happyFace from '../images/happyFace.jpeg'
import rice from '../images/rice.jpeg'
import dice from '../images/dice.png'
import camel from '../images/camel.jpg'
import mountain from '../images/mountain.jpeg'
import monkey from '../images/monkey.jpeg'
import duck from '../images/duck.jpg'
import dog from '../images/dog.jpeg'
import tijeras from '../images/tijeras.jpeg'
import beach from '../images/beach.jpeg'
import cup from '../images/cup.jpeg'
import diving from '../images/diving.jpeg'
import fancy from '../images/fancy.jpeg'
import fish from '../images/fish.jpeg'
import hat from '../images/hat.jpeg'
import hospital from '../images/hospital.jpeg'
import china from '../images/china.jpeg'
import mariposa from '../images/mariposa.jpeg'
import lengua from '../images/lengua.jpeg'
import mateSunset from '../images/mate-sunset.jpeg'
import plane from '../images/plane.jpeg'
import pool from '../images/pool.jpeg'
import shell from '../images/shell.jpeg'
import skate from '../images/skate.jpeg'
import smoke from '../images/smoke.jpeg'
import snowMountain from '../images/snow-mountain.jpeg'
import wine from '../images/wine.jpeg'
import snorkel from '../images/snorkel.jpeg'
import palm from '../images/palm.jpeg'
import tree from '../images/tree.png'
import coco from '../images/coco.jpeg'
import castle from '../images/castle.jpeg'
import bridge from '../images/bridge.jpeg'
import serious from '../images/serious.jpeg'
import pineapple from '../images/pineapple.jpeg'
import volcan from '../images/volcan.jpeg'
import flower from '../images/flower.jpeg'
import snowboard from '../images/snowboard.jpg'
import night from '../images/night.jpeg'
import bikini from '../images/bikini.jpeg'
import cat from '../images/cat.jpeg'
import falls from '../images/falls.jpeg'
import drink from '../images/drink.jpeg'
import zombie from '../images/zombie.jpeg'
import beer from '../images/beer.jpeg'
import moon from '../images/moon.jpg'
import dance from '../images/dance.jpg'
import van from '../images/van.jpg'
import joystick from '../images/joystick.jpeg'
import arms from '../images/arms.jpeg'
import boat from '../images/boat.jpeg'

const Dashboard = () => {
  const [emojiActive, setEmoji] = React.useState(null)

  const handleClick = (emojiName) => {
    setEmoji(emojiName)
  }

  const showBox = (emoji, name, flex, src) => (
    <div className={flex}>
      <button className='emoji-button' onClick={() => handleClick(name)}>{emoji}</button>
      <img src={src} className={emojiActive === name && 'animate-flip'} />
    </div>
  )
  return (
    <div>
      <h1>Feliz Cumple Flor! Te Amo  😍</h1>
      <div className='dashboard'>
        {showBox('🚐️', 'van', 'flex-50', van)}
        {showBox('🌌', 'moon', 'flex-50', moon)}
        {showBox('🐈', 'cat', 'flex-50', cat)}
        {showBox('👙', 'bikini', 'flex-50', bikini)}
        {showBox('🧟', 'zombie  ', 'flex-50', zombie)}
        {showBox('🍸', 'drink', 'flex-50', drink)}
        {showBox('🌃', 'night', 'flex-50', night)}
        {showBox('🥥', 'coco', 'flex-50', coco)}
        {showBox('🍺', 'beer', 'flex-50', beer)}
        {showBox('🏰', 'castle', 'flex-50', castle)}
        {showBox('💃', 'dance', 'flex-50', dance)}
        {showBox('🌉', 'bridge', 'flex-50', bridge)}
        {showBox('😐', 'serious', 'flex-50', serious)}
        {showBox('🎮', 'joystick', 'flex-50', joystick)}
        {showBox('🍍', 'pineapple', 'flex-50', pineapple)}
        {showBox('🌋', 'volcan', 'flex-50', volcan)}
        {showBox('🌹', 'flower', 'flex-50', flower)}
        {showBox('🏂', 'snowboard', 'flex-50', snowboard)}
        {showBox('🌴', 'palm', 'flex-50', palm)}
        {showBox('🌳', 'tree', 'flex-50', tree)}
        {showBox('😑', 'china', 'flex-50', china)}
        {showBox('🎱', 'pool', 'flex-50', pool)}
        {showBox('🐚', 'shell', 'flex-50', shell)}
        {showBox('🛹', 'skate', 'flex-50', skate)}
        {showBox('💨', 'smoke', 'flex-50', smoke)}
        {showBox('🏔️', 'snowMountain', 'flex-50', snowMountain)}
        {showBox('🍷', 'wine', 'flex-50', wine)}
        {showBox('🏊', 'snorkel', 'flex-50', snorkel)}‍
        {showBox('✈️', 'plane', 'flex-50', plane)}
        {showBox('🙌', 'arms', 'flex-50', arms)}
        {showBox('🐟', 'fish', 'flex-50', fish)}
        {showBox('🌅', 'mateSunset', 'flex-50', mateSunset)}
        {showBox('😝', 'lengua', 'flex-50', lengua)}
        {showBox('🏥', 'hospital', 'flex-50', hospital)}
        {showBox('🏆', 'cup', 'flex-50', cup)}
        {showBox('🎩', 'hat', 'flex-50', hat)}
        {showBox('🦋', 'mariposa', 'flex-50', mariposa)}
        {showBox('💄', 'fancy', 'flex-50', fancy)}
        {showBox('🏖', 'beach', 'flex-50', beach)}
        {showBox('🥽', 'diving', 'flex-50', diving)}
        {showBox('🐶', 'dog', 'flex-50', dog)}
        {showBox('✂️', 'tijeras', 'flex-50', tijeras)}
        {showBox('🌾', 'rice', 'flex-50', rice)}
        {showBox('🐪', 'camel', 'flex-50', camel)}
        {showBox('🏞', 'mountain', 'flex-50', mountain)}
        {showBox('🎲', 'dice', 'flex-50', dice)}
        {showBox('😀', 'happyFace', 'flex-50', happyFace)}
        {showBox('🦢', 'duck', 'flex-50', duck)}
        {showBox('⛵', 'boat', 'flex-50', boat)}
      </div>
    </div>
  )
}

export default Dashboard
