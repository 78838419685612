import * as React from "react"
import { Link } from 'gatsby'

import Dashboard from '../components/Dashboard'
import MasterPage from './MasterPage'

// markup
const IndexPage = () => {
  return (
    <MasterPage>
      <Dashboard />
    </MasterPage>
  )
}

export default IndexPage
